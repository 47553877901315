import "styles/pages/offer.scss";

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import Layout from "components/Layout";
import OfferChoice from "../page_components/offer/OfferChoice";
import ImagesBar from "components/ImagesBar";

const Offer = ({ data }) => {
	const [paramTab] = useQueryParam("tab", StringParam);
	const [defaultOpen, setDefaultOpen] = useState(true);

	useEffect(() => paramTab === "studio" && setDefaultOpen(false), []);

	const header_setup = {
		logo: "logo_black.svg",
		fontColor: "#000000",
		navbarColor: "#ffffff",
	};

	const seo = data.pageOffer?.nodes[0]?.seo;
	const offer_page_data = data.pageOffer?.nodes[0]?.pageOffer;

	return (
		<Layout seo={seo} header_setup={header_setup}>
			<section className="offer">
				<div className="container-fluid">
					<div className="site-container">
						<div className="section-title">
							{offer_page_data.offerPageTitle}
						</div>
						<div className="offer-profile">
							<div
								className={`offer-profile__btn${
									defaultOpen ? " active" : ""
								}`}
								onClick={() => setDefaultOpen(true)}
							>
								Maciej Uchmański
							</div>
							<div
								className={`offer-profile__btn${
									defaultOpen ? "" : " active"
								}`}
								onClick={() => setDefaultOpen(false)}
							>
								Studio Filmum
							</div>
						</div>
						<p className="offer-profile__desc">
							{offer_page_data.offerPageText}
						</p>
					</div>

					{defaultOpen ? (
						<OfferChoice offers={offer_page_data.offers} />
					) : (
						<OfferChoice offers={offer_page_data.offersFilmum} />
					)}
				</div>
			</section>

			<ImagesBar images={offer_page_data.offersGallery} />
		</Layout>
	);
};

export const query = graphql`
	{
		pageOffer: allWpPage(filter: { databaseId: { eq: 81 } }) {
			nodes {
				pageOffer {
					offerPageText
					offerPageTitle
					offers {
						... on WpOferta {
							link
							featuredImage {
								node {
									altText
									sourceUrl
								}
							}
							offerUchmanski {
								offerBelow
								offerDesc
								offerOver
								offerRedbox
								offerTitle
								offerAside {
									offerAsideImg {
										altText
										sourceUrl
									}
								}
							}
						}
					}
					offersFilmum {
						... on WpOfertaFilmum {
							link
							featuredImage {
								node {
									altText
									sourceUrl
								}
							}
							offerFilmum {
								offerBelow
								offerDesc
								offerRedbox
								offerOver
								offerTitle
								offerAside {
									offerAsideImg {
										altText
										sourceUrl
									}
								}
							}
						}
					}
					offersGallery {
						galleryImg {
							altText
							sourceUrl
						}
					}
				}
				seo {
					metaDesc
					title
				}
			}
		}
	}
`;

export default Offer;
