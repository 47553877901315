import "./styles.scss";

import React from "react";

import OfferCard from "../OfferCard";

const OfferChoice = ({ offers }) => {

    return (
      <div className="container-fluid">
        <div className="site-container">
          <div className="offer__items-wrapper">
            <div className="row">
              {offers?.map((offer, index) => {
                const img = offer.featuredImage.node;
                const link = offer.link;
                let chosen_offer;
                if (offer.offerUchmanski) {
                  chosen_offer = offer.offerUchmanski;
                } else {
                  chosen_offer = offer.offerFilmum;
                }
                return (
                  <OfferCard
                    link={link}
                    title={chosen_offer.offerTitle}
                    text={chosen_offer.offerDesc}
                    img={img}
                    key={index} />
                )
              })}
            </div>  
          </div>
        </div>
      </div>
  );
};

export default OfferChoice;