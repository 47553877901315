import "./styles.scss";

import React from "react";

const ImagesBar = ({ images } ) => {

    const backgroundImage = [];
    function createBackgroundImageVariables() {
        for (let i = 0; i <= 7; ++i) {
          backgroundImage[i] = {
            backgroundImage: `url(${images[i].galleryImg?.sourceUrl})`,
          };
        }
      }
      createBackgroundImageVariables();

    return (
    <section className="images-bar">
        <div className="container-fluid px-0 images-bar--wrapper">
        {backgroundImage?.map((item, index) => {
            return (
                <div key={index} className="images-bar__item">
                    <div className="images-bar__item__wrapper">
                        <div className="images-bar__item__box" style={item}></div>
                    </div>
                </div>  
                )
            }
        )}
        </div>
    </section>
  );
};

export default ImagesBar;