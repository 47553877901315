import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

const OfferCard = ({link, title, text, img}) => {

    return (
      <div className="col-xxl-3 col-xl-4 col-md-6 offer__box">
        <Link to={link}>
          <div className="offer__img">
            <div className="offer__img--wrapper">
              <img src={img.sourceUrl} alt={img.altText} />
            </div>
            <div className="offer__content">
              <h5>{title}</h5>
              <p>{text}</p>
            </div>
          </div>
        </Link>
      </div>
  );
};

export default OfferCard;